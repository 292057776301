<template>
  <section class="default-session home-see-more">
    <header class="is-hidden-mobile">
      <h1>Para você</h1>
    </header>

    <div class="see-more-list">
      <!-- <div class="see-more-item" :style="{'background-image': `url(${require('@assets/images/mockup/banner_2.png')})`}"> -->
      <!-- <div class="see-more-item" :style="{ 'background-image': `url(${require('@assets/images/banner-youtube.png')})` }"> -->
      <div
        class="see-more-item"
        :style="{
          'background-image': `url(http://www.meubs.com.br/cile/cile-digital/banners/banner-youtube.jpg)`,
        }"
      >
        <div class="see-more-info">
          <figure class="image">
            <img src="~@assets/images/logo-youtube.svg" />
          </figure>

          <p class="has-text-centered">Quer mais detalhes e curiosidades da obra?</p>
          <cile-button
            type="link"
            title="Assista aqui"
            buttonStyle="btn-green"
            :href="link"
          />
        </div>
      </div>

      <!--div class="see-more-item" :style="{'background-image': `url(${require('@assets/images/mockup/banner_3.png')})`}">
                <div class="see-more-info">
                    <figure class="image">
                        <img src="~@assets/images/icons/cile.svg" />
                    </figure>

                    <p class="has-text-centered">Fique por dentro com nosso feed de notícias</p>

                    <cile-button 
                        type="route" 
                        route="feedNews"
                        title="Ver notícias" 
                        buttonStyle="btn-green is-hidden-mobile" />
                </div>
            </div-->
    </div>
  </section>
</template>

<script>
import Button from "@components/shared/button/Button.vue";

export default {
  name: "SeeMore",
  components: {
    "cile-button": Button,
  },
  props: {
    link: {
      required: false,
      type: String,
    },
  },
};
</script>

<style lang="scss">
@use "sass:map";
@import "~@assets/css/config.scss";
@import "~@assets/css/mixins.scss";

.logged-area .main .home-see-more {
  height: 100%;

  .see-more-list {
    display: flex;
    height: calc(100% - 24px - 25px);

    .see-more-item {
      height: 100%;
      width: 100%;
      /*width: 50%;*/

      margin-left: 15px;
      margin-right: 15px;

      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
      /*background-position: center;*/

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }

      .see-more-info {
        text-align: center;

        height: 100%;
        padding: 25px;
        background-color: rgba(0, 0, 0, 0.3);

        figure,
        p {
          margin-bottom: 10px;
        }

        figure {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 65px;
        }

        img {
          width: 65px;
        }

        p {
          @include font-config(14px, 18px, map.get($font-weights, "semi-bold"));

          color: #ffffff;
        }

        .button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 769px), print {
  .logged-area .main .home-see-more .see-more-list .see-more-item {
    .see-more-info {
      -webkit-transform: translateY(calc(100% - 170px));
      transform: translateY(calc(100% - 170px));

      -webkit-transition: transform 0.6s ease-in-out, padding-top 0.4s ease-in-out;
      transition: transform 0.6s ease-in-out, padding-top 0.4s ease-in-out;

      figure,
      p {
        margin-bottom: 10px;

        -webkit-transition: margin-bottom 0.4s ease-in-out;
        transition: margin-bottom 0.4s ease-in-out;
      }

      p {
        margin-bottom: 60px;
      }

      figure img {
        -webkit-transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;

        -webkit-transform: scale(0.7);
        transform: scale(0.7);
      }
    }

    &:hover .see-more-info {
      -webkit-transform: translateY(0%);
      transform: translateY(0%);

      figure,
      p {
        margin-bottom: 20px;
      }

      img {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
}

@media screen and (max-width: 768px), print {
  .logged-area .main .home-see-more .see-more-list {
    .see-more-item {
      background-position: top center;

      .see-more-info {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        img {
          width: 40px;
        }

        p {
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 1215px), print {
  .logged-area .main .home-see-more .see-more-list .see-more-item .see-more-info .button {
    max-width: 250px;
  }
}

@media screen and (max-width: 1440px), print {
  .logged-area .main .home-see-more .see-more-list {
    .see-more-item {
      height: 285px;
    }
  }
}

@media screen and (max-width: 768px), print {
  .logged-area .main .home-see-more .see-more-list {
    .see-more-item {
      height: 250px;
    }
  }
}
</style>
